import React, { useEffect, memo } from 'react'
import './style.scss'

import BlocksRouter from '../../../Sections/BlocksRouter'
// import FormPromotion from '../../../Form/Axios/Promotion'

function Promotion(props) {

  const { singlePromotion } = props

  // useEffect(() => {
  //   const form = document.querySelector(`.klaviyo-form-${singlePromotion?.klaviyoId}`)
  //   if (form) {
  //     const formCloned = form.cloneNode(true)
  //     const target = document.querySelector('.klaviyo-form-placeholder')
  //     if (target)
  //       target.append(formCloned)
  //   }
  // }, [])

  const form = singlePromotion?.formUrl ?
    <iframe
      src={singlePromotion.formUrl}
      frameBorder="0"
      style={{ order: '100', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: singlePromotion?.formHeight ? singlePromotion.formHeight + 'px' : '500px', height: singlePromotion?.formHeight ? singlePromotion.formHeight + 'px' : '500px' }}
      title={`${singlePromotion.name} Contact Form`}
    />
    :
    null

  const blocksHero = singlePromotion ? [
    {
      __typename: 'ContentfulBlockImage',
      classes: 'flex ai-flex-start jc-flex-start border-left-off',
      image: {
        gatsbyImageData: singlePromotion?.featuredImage?.gatsbyImageData,
        description: singlePromotion?.featuredImage?.description,
      },
    },
    {
      __typename: 'ContentfulBlockWysiwyg',
      classes: 'flex ai-flex-end jc-flex-end text-right',
      content: singlePromotion.excerpt,
    },
    {
      __typename: 'tosection',
      tosection: 'promotion-more',
    },
  ] : null

  // Building up data block for invoking this page layout and content
  const blocksMore = singlePromotion?.content || form ? [
    {
      __typename: 'ContentfulBlockWysiwyg',
      classes: `flex padd span-8 margin-auto span-12-tablet`,
      content: { ...singlePromotion.content, jsx: form },
    },

  ] : null

  return (
    <>
      <section key={`promotion-hero`} className={`c0 section-hero section-promotion-hero`}>
        <div id="promotion-hero" className="anchor"></div>
        <BlocksRouter blocks={blocksHero} />
      </section>
      <section key={`promotion-more`} className={`c0 section-promotion-more flex-12 padd-top`}>
        <div id="promotion-more" className="anchor"></div>
        <BlocksRouter blocks={blocksMore} />
        {/* {
          singlePromotion?.klaviyoId ?
            <div className='span-4 span-12-mobile margin-auto padd padd-top-off'>
              <div className='klaviyo-form-placeholder'>
              </div>
            </div>
            :
            null
        } */}
        {/* {
          singlePromotion?.form ?
            <div className='span-4 span-12-mobile margin-auto padd padd-top-off'>
              <div className='form-container'>
                <FormPromotion name={singlePromotion.name} />
              </div>
            </div>
            :
            null
        } */}
      </section>
    </>
  )
}

export default memo(Promotion)