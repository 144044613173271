import React from 'react'
import Seo from '../../components/seo'

import MainFrameCover from '../../components/MainFrameCover'
import Footer from '../../components/Footer'

import Modal from '../../components/Modal'
import SubscribeBox from '../../components/Blocks/SubscribeBox'

import { graphql } from 'gatsby'
import Cookies from '../../components/Cookies'
import Promotion from '../../components/Blocks/CustomGulfstream/Promotion'


import LoginBox from '../../components/Blocks/LoginBox'
import IframeBox from '../../components/Blocks/IframeBox'
import TrackTrendsBox from '../../components/Blocks/TrackTrendsBox'


function PromotionTemplate(props) {

  const singlePromotion = props?.data?.contentfulPromotion

  return (
    <>

      <MainFrameCover />

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal>

      <Cookies />

      <Modal trigger={"#modal-1st-iframe"}>
        <IframeBox />
      </Modal>

      <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal>

      <Modal trigger={"#modal-1st-track-trends"}>
        <TrackTrendsBox />
      </Modal>

      <Cookies />

      <main id="mainframe" className={`c0 layer active main-${props.id}`}>

        <Seo title={singlePromotion?.metaTitle ? singlePromotion?.metaTitle : singlePromotion?.name} description={singlePromotion?.metaDescription ? singlePromotion.metaDescription : null} image={singlePromotion?.featuredImage?.file?.url ? singlePromotion.featuredImage.file.url : null} path={props.location?.pathname ? props.location?.pathname : ''} />

        <Promotion singlePromotion={singlePromotion} />

        <Footer />

      </main>
    </>
  )
}

export default PromotionTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulPromotion  ( slug: { eq: $slug } ) {
      name
          slug
          metaTitle
          metaDescription
          featuredImage {
            gatsbyImageData(quality: 70, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
            title
            description
						file {
							url
						}
					}
          excerpt {
						raw
          }
          content {
            raw
						references {
							... on ContentfulBlockButton {
								__typename
								contentful_id
								name
								classes
								label
								link
								size
							}
							... on ContentfulAsset {
								__typename
								contentful_id
								description
								gatsbyImageData(quality: 80, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
                title
                description
							}
						}
          }
          promotionday {
            date
          }
          formUrl
          formHeight
      }
  }
`