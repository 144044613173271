import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../animations/Intro'

function SubscribeBox(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<Intro visible={ioInView} in={{ bg: 600, fade: 400 }} delayIn={500} mounted={true} stay={true} className="c0  t">
			<div ref={io} className={`block-iframe-box c0 t ${props.classes}`}>
        <iframe width="800" height="1000" title="incompass-solutions" src="https://hbweb.incompass-solutions.com/"></iframe>
			</div>
		</Intro>
  )
}

export default SubscribeBox